import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetecter from "i18next-browser-languagedetector";

i18next
  .use(initReactI18next)
  .use(LanguageDetecter)
  .init({
    resources: {
      en: {
        translation: {
          Order: "Order",
          Takeaway: "Takeaway",
          Dining: "Dining",
          GrandTotal: "Grand Total",
          TableId: "Table ID",
          OrderId: "Order ID",
          TransId: "Transaction ID",
          PickTime: "Pick Up Time",
          Status: "Status",
          Image: "Image",
          IName: "Item Name",
          Price: "Price",
          OptionsN: "Option Name",
          Date: "CreatedAt",
          Time: "Time",
          No_: "Mobile Number",
          OrderType: "Order Type",
          total: "Total",
          Title: "Title",
          Desc: "Description",
          View: "View",
          Details: "Details",
          Info: "Information",
          Qty: "Quantity",
          Amount: "Amount",
          comment: "Any excepted request",
          Table: "Table",
          Powered: "Powered By",
          TF: "Table Finder",
          rating: "Ratings",
          open: "Open Now",
          type: "Type",
          selectOption: "Select Options",
          YourCart: "Your Cart",
          Summary: "Summary",
          Promo: "Promo Code",
          SubTotal: "Sub Total",
          Check: "Checkout",
          Review: "Order",
          OrderCon: "Order Confirmation",
          PS: "Payment Successfull",
          descOrder: "Your order has been placed successfully!.Please Rate Us",
          RTO: "Rate The Order",
          RYE: "Rate Your Experience",
          submit: "Submit",
          Bad: "Bad",
          Average: "Average",
          Good: "Good",
          Great: "Great",
          Awesome: "Awesome",
          Join: "Join Waitlist",
          CustN: "Customer Name",
          Email: "Email Address",
          NoPeople: "No. of People",
          CN: "Contact Number",
          Wait: "WaitList",
          descWait: "You are in Wait List",
          descWait2: "Thanks for your patience",
          descWait3: "You will get allocation at :",
          CheckWait: " Check Status",
          descWait4: "Please be Patient We are working on your request.",
          descWait5: "Click to Check Status",
        },
      },
      ar: {
        translation: {
          Order: "الطلبات",
          Takeaway: "الطلبات الخارجية",
          Dining: "الطلبات الداخلية",
          GrandTotal: "المجموع الإجمالي",
          TableId: "رقم الطاولة",
          OrderId: "رقم الطلب",
          TransId: "رقم العملية",
          PickTime: "وقت الاستلام",
          Status: "حالة الطلب",
          Image: "الصورة",
          IName: "اسم الصنف",
          Price: "لسعر",
          OptionsN: " الإضافة",
          Date: "تاريخ الطلب",
          No_: "رقم الجوال",
          OrderType: "نوع الطلب",
          total: "المبلغ الكلي",
          Title: "العنوان",
          Desc: "الوصف",
          View: "عرض",
          Details: "تفاصيل",
          Info: "معلومات",
          Qty: "الكمية",
          Amount: "المبلغ",
          comment: "ملاحظات خاصة",
          Items: "عناصر",
          Powered: "مشغل بواسطة",
          TF: "مكتشف الجدول",
          rating: "تقييم",
          open: "مفتوح الان",
          type: "يكتب",
          selectOption: "حدد الخيار",
          YourCart: "عربتك",
          Summary: "ملخص",
          Promo: "ملخص",
          SubTotal: "المجموع الفرعي",
          Check: "الدفع",
          Review: "مراجعة",
          OrderCon: "تأكيد الطلب",
          PS: "تأكيد الطلب",
          descOrder: "لقد تم تقديم طلبك بنجاح!.يرجى تقييمنا",
          RTO: "قيم الطلب",
          RYE: "قيم تجربتك",
          submit: "يُقدِّم",
          Bad: "سيء",
          Average: "متوسط",
          Good: "جيد",
          Great: "متوسط",
          Awesome: "مذهل",
          Join: "الانضمام إلى قائمة الانتظار",
          CustN: "اسم الزبون",
          Email: "بريد إلكتروني",
          NoPeople: "عدد الاشخاص",
          CN: "رقم الاتصال",
        },
      },
    },
    detection: {
      order: ["localStorage", "cookie", "htmlTag", "path", "subdomain"],
      caches: ["cookie"],
    },
    // lng: document.querySelector("html").lang,
    fallbackLng: "en",
    react: {
      useSuspense: false,
    },
    backend: {
      loadPath: "assets/lang/{{lng}}.json",
    },
  });
